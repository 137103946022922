import "./_snowpack/pkg/videojs-errors.js";
import "./_snowpack/pkg/videojs-errors/dist/videojs-errors.css";
import "./_snowpack/pkg/videojs/dist/video-js.css";
import setupPlayer from "./setup/setupPlayer.js";
import "./styles.css";
export const initPlayer = (videoElement, src, subtitleSrc) => {
  const source = document.createElement("source");
  source.src = src;
  videoElement.appendChild(source);
  if (subtitleSrc) {
    const track = document.createElement("track");
    track.kind = "captions";
    track.src = subtitleSrc;
    track.label = "English";
    track.srclang = "en";
    videoElement.appendChild(track);
  }
  return setupPlayer(videoElement);
};
export default initPlayer;
