import request from "./request.js";
const getVideoUrl = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get("id");
  try {
    return await request(`share/${id}`, "get");
  } catch (e) {
    return {data: {shareVideoLink: "", subTitleLink: null}};
  }
};
export default getVideoUrl;
