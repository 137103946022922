import getVideoUrl from "./api/getVideoUrl.js";
import setupAddons from "./setup/setupAddons.js";
import initPlayer from "./index.js";
const videoElement = document.getElementById("video");
videoElement.width = window.innerWidth;
videoElement.height = window.innerHeight;
getVideoUrl().then((src) => {
  if (videoElement instanceof HTMLVideoElement) {
    const player = initPlayer(videoElement, src.data.shareVideoLink, src.data.subTitleLink);
    setupAddons(player);
    videoElement.style.opacity = "100%";
  }
});
