import {API_URL} from "../utils/config.js";
import getUserAgent from "../utils/userAgentData.js";
const events = {
  INFO_EVENTS: ["loadeddata", "play", "pause", "playing", "ended"],
  ERROR_EVENTS: ["error", "stalled", "suspend"],
  CHANGE_EVENTS: ["seeked", "volumechange"],
  SCREEN_EVENTS: [
    "fullscreenchange",
    "leavepictureinpicture",
    "enterpictureinpicture"
  ]
};
const setupEvents = (player) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const videoId = urlSearchParams.get("id");
  let tmpDuration = 0;
  let startTime = 0;
  let fullscreen = false;
  const userAgent = getUserAgent();
  const sendMetrics = async (event) => {
    let duration = Number((player.currentTime() - startTime).toFixed(2));
    const volume = Math.floor(player.volume() * 100);
    if (event.type === "pause") {
      tmpDuration = duration;
    }
    if (event.type === "play" && tmpDuration > 0) {
      duration = tmpDuration;
    }
    const metrics = {
      play_event: event.type,
      play_duration: duration,
      play_error: event.type === "error" ? "yes" : "no",
      play_fullscreen: fullscreen,
      play_muted: player.muted(),
      play_volume: volume,
      video_length: player.duration()
    };
    const data = {
      share_id: videoId,
      player_metrics: {...userAgent, ...metrics}
    };
    const response = await fetch(`${API_URL}/metrics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
  };
  player.on("play", () => {
    startTime = player.currentTime();
  });
  Object.keys(events).forEach((key) => {
    player.on(events[key], (e) => {
      sendMetrics(e);
    });
  });
  player.on("fullscreenchange", () => {
    fullscreen = player.isFullscreen();
  });
};
export default setupEvents;
