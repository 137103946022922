import {API_URL} from "../utils/config.js";
const request = (url, method, body) => {
  const req = new Request(`${API_URL}/${url}`, {method, body});
  return fetch(req).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    throw new Error("failed to fetch");
  });
};
export default request;
