import UAParser from "../_snowpack/pkg/ua-parser-js.js";
const getUserAgent = () => {
  const {getOS, getBrowser, getDevice} = new UAParser();
  const getSize = () => `${window.screen.width}x${window.screen.height}`;
  const device = `${getDevice().vendor} ${getDevice().type} ${getDevice().model}`;
  const browser = `${getBrowser().name} ${getBrowser().version}`;
  const os = `${getOS().name} ${getOS().version}`;
  return {
    user_device: device,
    user_screen_size: getSize(),
    user_os: os,
    user_browser: browser
  };
};
export default getUserAgent;
