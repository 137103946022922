import errorSrc from "../assets/errorImg.js";
import addWatermark from "./addWatermark.js";
const setupErrorMessage = (player) => {
  const error = document.createElement("div");
  error.className = "error-message";
  const img = document.createElement("img");
  img.src = errorSrc;
  const msg = document.createElement("div");
  msg.innerText = "This video is unavailable";
  msg.className = "error-message-text";
  error.appendChild(img);
  error.appendChild(msg);
  player.on("error", () => {
    addWatermark(player);
    player.el().appendChild(error);
    player.errorDisplay.hide();
  });
};
export default setupErrorMessage;
