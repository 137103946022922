import videojs from "../_snowpack/pkg/videojs.js";
const setupPlayer = (videoElement) => {
  const options = {
    controlBar: {
      volumePanel: {inline: false}
    }
  };
  return videojs(videoElement, options);
};
export default setupPlayer;
