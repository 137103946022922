import logo from "../assets/logo.js";
import {VIME_STUDIO_URL} from "../utils/config.js";
const createLogoElement = (className) => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = VIME_STUDIO_URL;
  const image = document.createElement("img");
  image.src = logo;
  image.className = className;
  link.appendChild(image);
  return link;
};
export default createLogoElement;
